export default {
  appName: 'PhotoSir',
  login: {
    welcomeToLogin: 'Welcome to login',
    firstLogin: 'Unregistered users are considered to be registered if they use their mobile phone number to log in quickly',
    codeLogin: 'Login with verification code ',
    accountLogin: 'Login with account password ',
    inputPhone: 'Please enter your mobile phone number',
    inputCode: 'Please enter verification code',
    getCode: 'Get verification code',
    login: 'Login',
    inputAccount: 'Please enter the account number',
    inputPassword: 'Please enter the password',
    thirdPartyLogin: 'Third party login',
    loginAgreement: 'By logging in, you agree to ',
    agreement: 'PhotoSir software license and service agreement',
    and: ' and ',
    policy: 'Privacy Policy',
    privacyPolicyUrl: '/static/privacy_policy_en.html',
    userAgreementUrl: '/static/user_agreement_en.html',
    close: 'Close',
    charactersLong: '11 characters long',
    accountCannotBeEmpty: 'Account cannot be empty',
    loginSucceeded: 'Login succeeded',
    codeError: 'Verification code error!',
    accountError: 'Account error!',
    autoLogin: 'Automatic sign-in within 30 days',
    needNickName: 'Nickname must be entered',
    nickNameRules: ' Nickname between 2~12 characters, can be Chinese characters, letters and numbers',
    nickNameNoSpace: 'Nickname can\'t be entered as a space',
    realNameRules: 'Up to 12 characters in length',
    qqRules: 'Wrong format',
    textRules: 'The brief should be no longer than 200 words',
    emailRules: 'Wrong format',
    needAccount: 'Account number cannot be empty',
    needPass: 'Please input a password',
    needPhone: 'Please enter your phone number account',
    needCode: 'Please enter the verification code',
    wrongFormat: 'Wrong Format!'
  },
  editUser: {
    edit: 'Edit Profile',
    account: 'Account',
    nickName: 'Nick Name',
    realName: 'Real Name',
    enterName: 'Please enter your real name',
    sex: 'Gender',
    nan: 'Male',
    nv: 'Female',
    birthday: 'Birthday',
    text: 'Profile',
    clickToEdit: 'Click to edit',
    clickToDate: 'Please enter the date',
    time: 'Registration Time',
    email: 'Email',
    notBind: 'Unbound',
    close: 'Close',
    save: 'Save',
    enterNickName: 'Please enter nickname',
    success: 'Success!'
  },
  editPassword: {
    title: 'Change Password',
    old: 'Original password',
    new: 'New Password',
    check: 'Check Password',
    enterNew: 'Please enter the new password',
    enter: 'Please enter the password again',
    text: 'Password between 8-20 characters, must contain numbers, letters or symbols, at least 2 or more elements',
    send: 'Submit',
    reset: 'Reset',
    notSame: 'Inconsistency between two passwords!',
    changeSuccess: 'The modification was successful!',
    sending: 'Sent, please check carefully!'
  },
  setPassword: {
    title: 'Set Password',
    phone: 'e-mail',
    ma: 'Verification Code',
    enterPassword: 'Enter password',
    check: 'Confirm Password',
    enter: ' Please enter your password again',
    text: 'Password between 8-20 characters, must contain numbers, letters or symbols, at least 2 or more elements',
    send: 'Submit',
    reset: 'Reset',
    notSame: 'Inconsistency between two passwords!',
    changeSuccess: 'The modification was successful!',
    bind: 'Please bind the email first!',
    success: 'Email binding successful!'
  },
  changePhone: {
    title: 'Change mobile phone number',
    phone: 'Original phone number',
    ma: 'Verification code',
    newPhone: 'New phone number',
    enterPhone: 'Please enter the phone number to be replaced',
    enterMa: 'Please enter verification code',
    send: 'Submit',
    reset: 'Reset',
    getMa: 'Get verification code'
  },
  bindWX: {
    title: 'Bind to Wechat',
    phone: 'Phone Number',
    ma: 'Verification code',
    getMa: 'Get verification code',
    enterMa: 'Please enter verification code',
    send: 'Submit',
    bindFirst: 'Please bind phone number first!',
    enterPhone: 'Please enter phone number'
  },
  feedback: {
    title: 'Feedback',
    ways: 'Your contact info:',
    enterWays: 'Please enter your contact info:',
    problems: 'Please select what problems you are experiencing with your use:',
    disProblems: 'Please describe in detail the problems that occur in the use of PhotoSir',
    text: 'Please describe in detail the problems you have in use, which will help us quickly locate the cause of the problem and provide you with better service!',
    score: 'Software Rating:',
    suggestion: 'Give us suggestions',
    send: 'Submit',
    enterPhone: 'Please enter phone number',
    cannotUse: 'Error',
    wifi: 'Network Error',
    bug: 'Flashback/Break down',
    slow: 'Too slow',
    tooDifficult: 'Too complex',
    other: 'Other',
    no: 'No problem',
    success: 'Thank you for your suggestion. We will handle it as soon as possible！',
    select: 'Please select the problem you have encountered!'
  },
  logout: {
    title: 'Tip',
    text: 'This action will log you out, do you want to continue?',
    cancel: 'Cancel',
    send: 'Confirm',
    success: 'Logged out！'
  },
  layoutMain: {
    edit: 'Edit Profile',
    mainPage: 'Home Page',
    party: 'Team management',
    message: 'Message',
    notice: 'message notification',
    vip: 'Vip card activation',
    download: 'Download',
    buy: 'Orders',
    discount: 'Discount coupons',
    cloudSpace: 'Cloud Space',
    creater: 'Creator Center',
    setPic: 'Set avatar',
    seePic: 'Picture preview',
    changePic: 'Change avatar',
    cancel: 'Cancel',
    confirm: 'Confirm',
    superVip: 'SVIP',
    active: 'Activate it now',
    expire: 'expire',
    renewal: 'renewal'
  },
  editUserIndex: {
    changePass: 'Change Password',
    setPass: 'Set password',
    bindPhone: 'Bind email',
    changeBind: 'Change phone number',
    feedBack: 'Feedback',
    logOut: 'Logout'
  },
  mainPage: {
    title: 'Cloud Space',
    option: 'Expansion',
    enter: 'Enter',
    money: 'Renewal',
    pspace: 'Personal space',
    cspace: 'Enterprise space'
  },
  cloudSpace: {
    title: 'All Files',
    search: 'Search',
    document: 'Documents',
    upload: 'Upload',
    nodata: 'No Data',
    uptime: 'Upload Time',
    size: 'Size',
    send: 'Publish',
    chooseAll: 'Choose All Files',
    searchRes: 'Search Result',
    uploadFile: 'Upload Files',
    chooseFile: 'Choose Files',
    max: 'You can upload up to 4 files',
    newFile: 'Create Folder',
    rename: 'Rename',
    delete: 'Delete',
    chooseDelete: 'Please select the folder you want to delete',
    warning1: 'Once deleted, if the file has been shared with others, others will not be able to access it either!',
    warning2: 'Delete file',
    confirm: 'Confirm',
    cancel: 'Cancel',
    garbage: 'Recycle Bin',
    clean: 'Cleanup',
    cleanTip: 'Operation successful!',
    cleanTip2: 'No data cleaning currently available!',
    cleanTip3: 'Please select the data to be operated on first!',
    cleanTip4: 'Do you want to delete all files? Files cannot be recovered after deletion!',
    name: 'Name',
    deltime: 'Delete time',
    delperson: 'Deleter',
    see: 'View',
    open: 'Open',
    download: 'Download',
    move: 'Move to...',
    copy: 'Copy to...',
    share: 'Share',
    paste: 'Paste',
    Management: 'Permissions',
    moreDel: 'Delete in bulk',
    moreMove: 'Move in bulk',
    moreCopy: 'Copy in bulk',
    toBefore: 'Recover',
    deleteOnce: 'Delete completely',
    chooseMoveFolder: 'Select the file or folder you want to move!',
    chooseMoveTarget: 'Please choose the location you want to move to!',
    chooseCopyTarget: 'Please choose the location you want to copy to!',
    chooseWrong: 'You have selected a folder, please select a file!',
    newFolder: 'New Folder',
    newFolderTip: 'The file name can only be entered in Chinese and English, Chinese and English symbols, and numbers!',
    uploadTohere: 'Upload to here',
    enterName: 'Please enter file name',
    uploading: 'uploading'
  },
  upLoadWorks: {
    title: 'Publish Works',
    type: 'Works Type',
    style: 'Works Style',
    publish: 'Submit Work',
    text1: 'Please enter the title of the work, up to 10 words',
    addWorks: 'Add Works',
    text2: 'After entering, press enter, and each label is limited to 10 words',
    text3: 'Add tags to automatically recommend your work to people who might be interested',
    text4: 'Enter a description of your work',
    back: 'Back',
    waiting: 'Submit for review',
    needName: 'Please enter the name of your work',
    needStyle: 'Please choose your works style',
    needDocument: 'Please choose your works document',
    needLabel: 'Please enter your works label',
    needPrePic: 'Please choose your works preview picture',
    needType: 'Please choose your works type',
    nameLong: 'The title of the work can be up to 10 words',
    sizeMax: 'The uploaded preview image cannot be larger than 3 MB',
    sizeRules: 'Upload preview size: width 400~2000 pixels, height 400~2000 pixels!',
    sizePicMax: 'The uploaded file cannot be larger than 64MB!',
    onlyOne: 'Upload up to one!'
  },
  payForCloud: {
    title: 'Cloud Space Expansion',
    pay: 'Cloud Space Renewal',
    payoff85: '85% off',
    payoff66: '66% off',
    yearpayoff: 'Discounts for more than 1 year',
    year: 'year',
    time: 'Valid until',
    infact: 'Actual amount paid',
    yuan: '$',
    buy: 'Pay for it'
  },
  moveDiv: {
    title: 'Move',
    copy: 'Copy',
    movetoHere: 'Move to',
    copytoHere: 'Copy to',
    cancel: 'Cancel',
    files: 'Files',
    time: 'Upload Time',
    size: 'Size',
    allFiles: 'All files',
    enterName: 'Please enter file name'
  },
  share: {
    text1: 'Leading ⌜AI+Design⌟',
    text2: 'Shared a picture',
    text3: 'Long press or screenshot to save sharing',
    btnText1: 'Share with friends',
    btnText2: 'Copy image link',
    btnText3: 'Save image to local',
    btnText4: 'Please enter the extraction code',
    user: 'user',
    confirm: 'confirm'
  },
  memberActivation: {
    title: 'Photosir PC activation',
    hello: 'nice to meet you!',
    ma: 'Activation code',
    enter: '* Please enter the 16-digit activation code, the letters are case-sensitive',
    imm: 'Activate now',
    check: 'Query duration',
    info: 'Instructions for using the activation code：',
    text1: 'The duration of activation is:',
    month: 'month',
    confirm: 'confirm',
    text2: '1. Please activate within the validity period, the activation code will be invalid after the expiration date.',
    text3: '2. The membership validity period is calculated from the activation date, and the membership period will be automatically extended if multiple activation codes are used for the same account.',
    text4: '3. If you are currently an ordinary member, after activating the super member, the super member will be consumed first, and after the super member expires, you will be restored to the ordinary member.',
    text5: '4. After successful activation, you can enjoy Wukong membership services.',
    text6: '5. To the extent permitted by law, Wukong has the right to interpret, if you have any questions, please contact customer service.',
    success: 'Congratulations, the activation is successful!',
    tip: 'Tip',
    text7: 'Open membership, automatically activated after activation',
    text8: " If you don't have an activation code, you can",
    text9: 'What is the duration of activation',
    text10: 'Month',
    text11: 'click here'
  },
  cloudNone: {
    none: 'none'
  },
  main: {
    all: 'Total',
    page: '',
    message: '',
    change: 'page',
    jump: 'Go to'
  },
  loginTitle: {
    title: 'Photosir'
  },
  routers: {
    login: 'Login',
    mainPage: 'Homepage',
    user: 'User',
    cloudSpace: 'Cloudspace'
  },
  logoff: {
    logoffTitle: 'Account Cancellation',
    text1: 'To ensure the security of your account, before your submitted cancellation request takes effect, the following conditions must be met:',
    text2: '1. Account is in a secure state',
    text3: 'The account is in normal use and free from risks of being compromised or abnormal statuses such as being banned.',
    text4: '2. Settlement of account assets',
    text5: 'There are no assets, debts, unsettled funds, or virtual rights. There are no unfinished or disputed services associated with this account or accessed through this account from third-party providers.',
    text6: '3. Removal of account permissions',
    text7: 'The account has been unlinked from or unbound from authorization to access other products.',
    text8: '4. Absence of any disputes related to the account, including complaints, reports, or lawsuits.',
    text9: 'You are cancelling your account and we meed to verify your identity',
    text10: 'Your account has been successfully cancelled. Thank you for using our services and we look forward to see you again!',
    text11: 'Team Yeeheart',
    read: 'I have read and agreed to the',
    agree: '"Account Cancellation Agreement"',
    logoffBtn: 'Account Cancellation',
    logoffedBtn: 'Account canceled',
    dialogTitle: 'Account Cancellation Agreement',
    logoffSuccess: 'Successfully canceled',
    logoffUrl: '/static/logoff_agreement_pad_en.html',
    verify: 'VERIFY',
    finish: 'FINISH',
    cancel: 'cancel',
    confirm: 'confirm',
    logoffTip: 'Are you sure you want to cancel this account? ',
    logoffMsg: 'Please log in first to proceed with the cancellation!'
  },
  giftGet: {
    title: 'User gift package',
    Collection: 'Receiving in progress',
    CollectionFailed: 'Collection failed',
    CollectionSuccess: 'Received successfully',
    OnlineStrategy: 'Unable to detect network',
    prompt: 'parameter error',
    rule: 'List of Super Membership Benefits',
    networkAnomaly: 'network anomaly',
    timeSlot: 'Membership expiration date'
  }
}
